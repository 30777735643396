<template>
  <Layout tituloPagina="Inventario | Salidas">
    <div class="row">
      <div class="col-lg-3 col-md-5 mb-3"> 
        <filtrador
          v-on:dato-actualizado="
            dato_en_nota = $event,
            refrescarSalidas()
          "
          :datoini="dato_en_nota"
          placeholder="Dato de la nota de la entrada"
        ></filtrador>
      </div>
      <div class="col-md-1 p-2">
        <div class="form-check" style="display: inline-block;">
          <input
            id="personalizado" type="checkbox"
            @change="checkedPersonalizado()"
            class="form-check-input form-check-input-success"
          />
          <label
            for="personalizado" class="form-check-label"
            :class="{ 'text-success': personalizado == true }"
          >
            <strong>Personalizado</strong>
          </label>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-4 mb-3">
        <div class="input-group">
          <span class="input-group-text">
            Salida de almacén
          </span>
          <input
            class="form-control"
            v-model="id_salalm"
            @keyup.enter="refrescarSalidas()"
            placeholder="ID de salida de almacén"
          />
          <button class="btn btn-success" @click="refrescarSalidas()">
            <i class="mdi mdi-filter"></i>
          </button>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mb-3">
        <div class="input-group">
          <span class="input-group-text">
            Artículo
          </span>
          <input
            class="form-control" v-model="nombre_articulo"
            placeholder="Nombre del articulo"
            disabled
          />
          <button
            class="btn btn-warning waves-effect waves-lightt"
            @click="seleccionarArticulo()" :title="id_articulo == null ? 'Seleccionar' : 'Cambiar'"
          >
            {{id_articulo == null ? 'Seleccionar' : 'Cambiar'}}
          </button>
          <button
            class="btn btn-danger waves-effect waves-light"
            @click="limpiarArticulo()" title="Limpia el artículo"
          >
            <i class="mdi mdi-trash-can"></i>
          </button>
          <button class="btn btn-success waves-effect waves-light"
            @click="refrescarSalidas()"
          >
            <i class="mdi mdi-filter"></i>
          </button>
        </div>
      </div>
      <div class="col-md-4 text-right">
        <router-link class="btn btn-success"
          :to="{name: 'nuevaSalidaAlmacen'}">
          <i class="mdi mdi-plus-thick"></i>
          Nueva salida
        </router-link>
      </div>
    </div>

    <br v-if="personalizado== true">
    <div class="row" v-if="personalizado == true">
      <div class="offset-md-2 col-md-4">
        <label for="">Fecha inicial</label>
        <Datepicker
          style="background: white;"
          ref="fecha_inicial"
          v-model="fecha_inicial"
          placeholder="dd/mm/aaaa"
          :class="'form-control'"
          :inputFormat="'dd/MM/yyyy'"
        />
      </div>
      <div class="col-md-4" v-if="dia != true || personalizado == true">
        <label for="">Fecha Final</label>
        <Datepicker
          style="background: white;"
          ref="fecha_final"
          v-model="fecha_final"
          placeholder="dd/mm/aaaa"
          :class="'form-control'"
          :inputFormat="'dd/MM/yyyy'"
          v-if="dia != true || personalizado == true"
        />
      </div>
      <div class="col-md-2 p-4">
        <button class="btn btn-secondary"
          @click="refrescarSalidas()"
        >
          <i class="mdi mdi-refresh"></i>
        </button>
      </div>
    </div>
    <br>
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table id="tblListaSalidas" class="table table-hover table-nowrap">
            <thead>
              <tr>
                <th style="width: 50px">ID</th>
                <th style="width: 100px">Fecha</th>
                <th style="width: 100px">Hora</th>
                <th>Tipo</th>
                <th>Almacenista que registró la salida</th>
                <th>Solicitante de los artículos</th>
                <th style="width:120px;">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="salida in salidas" :key="`ent-${salida.id}`">
                <td>{{salida.id_salalm}}</td>
                <td>{{formatoFecha(salida.created_at)}}</td>
                <td>{{formatoHora(salida.created_at)}}</td>
                <td>
                  <span class="badge bg-warning bg-gradient font-size-12" v-show="salida.tipo == 1">
                    Salida sin solicitud de artículos
                  </span>
                  <span class="badge bg-info bg-gradient font-size-12" v-show="salida.tipo == 3">
                    Salida por conversión de artículos
                  </span>
                  <span class="badge bg-primary bg-gradient font-size-12" v-show="salida.tipo == 4">
                    Salida por solicitud de artículos
                  </span>
                </td>
                <td>{{salida.nombre_almacenista}}</td>
                <td>{{salida.nombre_solicitante}}</td>
                <td>
                  <router-link
                    :to="{ name: 'visorSalidaAlmacen', params: { id: salida.id } }"
                    class="btn btn-info btn-sm info"
                  >
                    <i class="mdi mdi-eye"></i>
                    Mostrar
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarSalidas()
        "
      ></paginador>
    </div>
    <mdl-seleccionar-articulo
      ref="seleccionarArticulo"
      v-on:articulo-seleccionado="cargarArticulo">
    </mdl-seleccionar-articulo>
    <br>
  </Layout>
</template>
    
<script>
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import SistemaSrv from '@/services/SistemaSrv.js'
import SalidaSrv from '@/services/inventario/SalidaSrv.js'
import MdlSeleccionarArticulo from '../entradas/MdlSeleccionarArticulo.vue'
import moment from 'moment'
import Datepicker from 'vue3-datepicker'
import Layout from "@/views/layouts/main"

export default {
  name: 'ListadoSalidasArticulos',
  components: { 
    Layout, 
    Datepicker,
    Paginador,
    Filtrador,
    MdlSeleccionarArticulo
  },
  data: function() {
    return {
      entradas: [],
      dato_en_nota: '',
      //dato de paguinacion
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoSalidasAlmacen.por_pagina') || 10),
      tc: 0, // Total de Movimientos
      totpag: 0, // Total de páginas
      personalizado: false,
      fecha_inicial: '',
      fecha_final: '',
      id_entalm: null,
      id_articulo: null,
      nombre_articulo: ''
    }
  },
  watch: {
    fecha_inicial : function(newVal, oldVal) {
      var self = this 
      self.refrescarSalidas()
    },
    fecha_final : function(newVal, oldVal) {
      var self = this 
      self.refrescarSalidas()
    },
    id_entalm: function (newId){
      if(newId == '') this.id_entalm = null
      if(newId == 0) this.id_entalm = null
    }
  },
  created() {
    var self = this

    if (self.$route.query.dato_en_nota) self.dato_en_nota = self.$route.query.dato_en_nota
    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp) self.epp = parseInt(self.$route.query.epp)
    if (self.$route.query.id_salalm) self.id_salalm = parseInt(self.$route.query.id_salalm)
    if (self.$route.query.id_articulo) self.id_articulo = parseInt(self.$route.query.id_articulo)
    if (self.$route.query.nombre_articulo) self.nombre_articulo = self.$route.query.nombre_articulo

    //Carga de la fecha inicial del sistema
    if (self.fecha_inicial == '') {
      SistemaSrv.fecha({formato: 'Y-m-d'}).then(response => {
        self.fecha_inicial = new Date(moment(response.data).format())
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    }
    //Carga de la fecha inicial del sistema
    if (self.fecha_final == '') {
      SistemaSrv.fecha({formato: 'Y-m-d'}).then(response => {
        self.fecha_final = new Date(moment(response.data).format())
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    }
    self.refrescarSalidas()
  },
  methods: {
    cargarArticulo(articulo){
      var self = this
      self.id_articulo = articulo.id
      self.nombre_articulo = articulo.nombre

      self.refrescarSalidas()
    },
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarSalidas({ page: n })
    },
    checkedPersonalizado: function() {
      var self = this

      if(self.personalizado != true) self.personalizado = true
      else self.personalizado = false
      if (self.personalizado == true) {
        document.getElementById("personalizado").checked = true
      } else {
        document.getElementById("personalizado").checked = false
      }

      self.refrescarSalidas()
    },
    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },
    formatoFechaBD(fecha) {
      return moment(fecha, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },
    formatoHora(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
    },
    refrescarSalidas: function() {
      var self = this

      iu.spinner.mostrar('#tblListaSalidas')

      this.$router.replace({
        query: {
          dato_en_nota: self.dato_en_nota,
          page: self.page,
          epp: self.epp,
          id_salalm: self.id_salalm,
          id_articulo: self.id_articulo,
          nombre_articulo: self.nombre_articulo
        }
      }).catch(error => {})

      let params = {
        dato_en_nota: self.dato_en_nota,
        page: self.page,
        epp: self.epp,
        orden: 'desc',
        id_salalm: self.id_salalm,
        id_articulo: self.id_articulo
      }

      if(self.personalizado){
        Object.assign(params,{
          fecha_inicial: self.formatoFechaBD(self.fecha_inicial),
          fecha_final: self.formatoFechaBD(self.fecha_final)
        })
      }
      
      SalidaSrv.salidasJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoSalidasAlmacen.por_pagina', self.epp)
        let page = response.data
        self.salidas = page.data
        self.tc = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarSalidas()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las salidas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblListaSalidas')
      })
    },

    seleccionarArticulo: function(){ 
      var self = this
      self.$refs.seleccionarArticulo.mostrar()
    },
    limpiarArticulo:function () {
      var self = this 
      self.id_articulo = null
      self.nombre_articulo = ''

      self.refrescarSalidas()
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tblListaSalidas')
  }
}
</script>

<style scoped>
.form-check-input-success:checked {
  background-color: #63AD6F;
  color: #63AD6F;
  border-color: #63AD6F;
}
</style>